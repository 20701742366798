import { isComplexFilterArr } from "@components/conditionalFilterDialog/ConditionalFilterDialog.utils";
import { ISelectItem } from "@components/inputs/select/Select.types";
import { IGetValueArgs } from "@components/smart/FieldInfo";
import { IAffectedRow, TSmartODataTableStorage } from "@components/smart/smartTable/SmartODataTableBase";
import { IFieldInfo } from "@odata/FieldInfo.utils";
import { IBankAccountEntity, IBusinessPartnerEntity, ICompanyBankAccountEntity } from "@odata/GeneratedEntityTypes";
import { CompanyBankAccountTypeCode, CountryCode } from "@odata/GeneratedEnums";
import { ICreateFilterStringSettings, IFormatOptions } from "@odata/OData.utils";
import {
    buildComplexBankAccountFilterFromBankAccountString,
    changeCountryByIBAN,
    isBankAccount,
    setBankValue,
    tryToCreateIBAN
} from "@pages/banks/bankAccounts/BankAccounts.utils";
import { getAgendaCountryCode } from "@pages/companies/Company.shared.utils";
import Iban from "iban";

import { REST_API_URL } from "../constants";
import { BankAccountType, GroupedField, Status } from "../enums";
import { TRecordAny, TValue } from "../global.types";
import { IFilterQuery, TableStorage } from "../model/TableStorage";
import BindingContext from "../odata/BindingContext";
import { FormStorage } from "../views/formView/FormStorage";
import { IChangedFilter } from "../views/table/TableView.utils";
import customFetch, { getDefaultPostParams } from "./customFetch";
import { isNotDefined } from "./general";
import { removeWhiteSpace, trimLeadingZeroes } from "./string";

export const BANK_ACCOUNT_COUNTRIES = ["CZ", "US"];
export const BANK_ACCOUNT_BANKS_COUNTRIES = ["CZ"];
export const BANK_ABA_COUNTRIES = ["US"];

export interface IHandleBlur {
    storage: FormStorage;
    bindingContext: BindingContext;
    root: BindingContext;
}

export const ibanValidator = (value: TValue): boolean => {
    return !value || Iban.isValid(value as string);
};

export const correctBankCode = (code: string): string => {
    if (code?.length < 4) {
        return code.padStart(4, "0");
    }

    return code;
};

export const createIBANFromAccount = (accountNumber: string, bankCode: string, country: string): string => {
    if (isNotDefined(accountNumber) || isNotDefined(bankCode)) {
        return "";
    }

    const split = accountNumber?.split("-");
    const pre = split.length > 1 ? split[0].padStart(6, "0") : "000000";
    const number = split[1] ?? split[0];
    const formatted = `${bankCode}${pre}${number.padStart(10, "0")}`;

    try {
        return Iban.fromBBAN(country, formatted);
    } catch (e) {
        return "";
    }
};

export const createAccountFromIBAN = (IBAN: string): { bank: string, account: string } => {
    try {
        const acc = Iban.toBBAN(IBAN, "/");

        const [bankCode, prefix, accountNumber] = acc.split("/");

        // remove empty 000 in the start of account
        const trimmed = parseInt(prefix, 10);
        const account = trimmed ? `${trimmed}-${accountNumber}` : accountNumber;

        return {
            bank: bankCode,
            account: account
        };
    } catch (e) {
        return undefined;
    }
};

export const generalBankAccountFormatter = (val: TValue, args?: IFormatOptions): string => {
    const account: ICompanyBankAccountEntity = (args.item?.BankAccount ?? args.item ?? args.entity?.BankAccount) as IBankAccountEntity;
    const ifEmptyValue = val?.toString() || args.placeholder;

    if (account) {
        if (Array.isArray(account.AccountNumber)) {
            return val?.toString();
        }

        const type = getTypeFromAccount(account);
        return formatBankAccount(account, type) || ifEmptyValue;
    }

    return ifEmptyValue;
};

export const getBankAccountFilterDefinition = (prefix: string, includeAccountNumber?: boolean): IFieldInfo["filter"] => {
    const groupByProperties = [`${prefix}/BankCode`, `${prefix}/CountryCode`, `${prefix}/AbaNumber`, `${prefix}/PaymentServiceID`, `${prefix}/IBAN`];

    if (includeAccountNumber) {
        groupByProperties.push(`${prefix}/AccountNumber`);
    }

    return {
        groupByProperties,
        buildFilter: (item: IChangedFilter, settings: ICreateFilterStringSettings, storage: TableStorage): IFilterQuery | string => {
            if (isComplexFilterArr(item.value)) {
                return buildComplexBankAccountFilterFromBankAccountString(item, storage, settings);
            } else {
                // call original function that build filter automatically
                return storage.createFilterQueryRecursive([{
                    ...item,
                    // we need the bc to always be set to AccountNumber, but this function is used from Account navigation filter also
                    bindingContext: includeAccountNumber ? item.bindingContext.navigate("AccountNumber") : item.bindingContext,
                    info: {
                        ...item.info,
                        filter: {
                            ...item.info.filter,
                            // but prevent it from calling this callback again
                            buildFilter: null
                        }
                    }
                }], settings.rootBindingContext);
            }
        }
    };
};

export const getTypeFromAccount = (account: IBankAccountEntity): BankAccountType => {
    // some accounts are loaded with Country as object with Code string
    const country = account.CountryCode ?? account?.Country?.Code;

    if ((account as ICompanyBankAccountEntity).CompanyBankAccountTypeCode === CompanyBankAccountTypeCode.PaymentService) {
        return BankAccountType.Service;
    }

    if (isAbaCountry(country)) {
        return BankAccountType.ABA;
    }

    if (isAccountNumberCountry(country)) {
        return BankAccountType.Account;
    }

    return BankAccountType.IBANOnly;
};

export const formatBankAccountWithoutType = (acc: IBankAccountEntity): string => {
    if (acc) {
        const accountNumber = acc.AccountNumber?.replace(/^0+-/, "");

        if (acc.BankCode && acc.AccountNumber) {
            return `${accountNumber}/${acc.BankCode}`;
        }
        if (acc.AbaNumber && acc.AccountNumber) {
            return `${acc.AbaNumber}/${accountNumber}`;
        }

        return acc.IBAN ?? "";
    }

    return "";
};

export const formatBankAccount = (bankAccount: ICompanyBankAccountEntity, type: BankAccountType): string => {
    if (bankAccount.PaymentServiceID) {
        return bankAccount.PaymentServiceID;
    }

    let accountNumber = bankAccount.AccountNumber;
    accountNumber = accountNumber?.replace(/^0+-/, "");

    switch (type) {
        case BankAccountType.ABA:
            return `${bankAccount.AbaNumber}/${accountNumber}`;

        case BankAccountType.Account:
            return accountNumber && bankAccount.BankCode ? `${accountNumber}/${bankAccount.BankCode}` : "";

    }

    return ibanFormatter(bankAccount.IBAN);
};

export interface IAllBankAccount extends IBankAccountEntity {
    BusinessPartner: IBusinessPartnerEntity;
}

interface BankAccountsItemsArgs {
    bankAccounts: ICompanyBankAccountEntity[];
    hasBusinessPartnerCol?: boolean;
    allowedTypes?: BankAccountType[];
    addCurrency?: boolean;
}

export const createBankAccountsItems = (args: BankAccountsItemsArgs): ISelectItem[] => {
    const groups: Record<BankAccountType, ISelectItem[]> = {
        [BankAccountType.Account]: [],
        [BankAccountType.IBANOnly]: [],
        [BankAccountType.ABA]: [],
        [BankAccountType.Service]: []
    };

    for (const bankAccount of args.bankAccounts || []) {
        const id = bankAccount.Id ?? (bankAccount as TRecordAny).Code ?? `#${(bankAccount as TRecordAny)[BindingContext.NEW_ENTITY_ID_PROP]}`;
        const type = getTypeFromAccount(bankAccount);
        if (args.allowedTypes && !args.allowedTypes.includes(type)) {
            continue;
        }
        const item: ISelectItem = {
            id: id,
            label: formatBankAccount(bankAccount, type),
            groupId: type,
            additionalData: bankAccount
        };

        if (bankAccount.Name) {
            item.tabularData = [item.label];
            if (args.addCurrency) {
                item.tabularData.push(item.additionalData?.TransactionCurrency?.Code || "");
            }

            item.tabularData.push(bankAccount.Name);
        }

        if (args.hasBusinessPartnerCol) {
            item.tabularData = [item.label, (bankAccount as IAllBankAccount).BusinessPartner?.Name];
        }


        groups[type].push(item);
    }

    return [...groups.account, ...groups.ibanOnly, ...groups.aba, ...groups.service];
};

export const ibanFormatter = (val: TValue): string => {
    let iban = val as string;
    if (Iban.isValid(iban)) {
        iban = removeWhiteSpace(iban);

        const newStringArr: string[] = [];
        const SPACING = 4;
        for (let i = 0, len = iban.length; i < len; i += SPACING) {
            newStringArr.push(iban.slice(i, i + SPACING));
        }

        return newStringArr.join(" ").toUpperCase();
    }

    return iban ?? "";
};

export const getBc = (args: IHandleBlur, name: string): BindingContext => {
    return args.root ? args.root.navigate(name) : args.storage.data.bindingContext.navigate(name);
};

export const handleBankAccountOrIBANBlur = (args: IHandleBlur): void => {
    const path = args.bindingContext.getPath(true);

    if (isBankAccount(args.root)) {
        if (path === "IBAN") {
            handleIBANBlur(args);
        }

        if (path === "AccountNumber" || path === "Bank") {
            handleBankAccountBlur(args);
        }
    }
};

const setDefaultCountry = (storage: FormStorage, countryBc: BindingContext) => {
    const country = storage.getValue(countryBc, { useDirectValue: false });
    if (!country) {
        const defaultCountry = getAgendaCountryCode(storage.context, null);
        if (defaultCountry === CountryCode.CzechRepublic || defaultCountry === CountryCode.Slovakia) {
            storage.setValue(countryBc, defaultCountry);
        }
    }
};

export const handleIBANBlur = (args: IHandleBlur): void => {
    changeCountryByIBAN(args.storage, args.root);
    args.storage.refresh();
};


export const handleBankAccountBlur = (args: IHandleBlur): void => {
    const bcAccount = getBc(args, "AccountNumber");
    let account = args.storage.getValue(bcAccount);

    const splitted = account?.split("/");

    const countryBc = getBc(args, "Country");

    if (splitted && splitted.length === 2) {
        setDefaultCountry(args.storage, countryBc);
        args.storage.setValue(bcAccount, splitted[0]);
        // we have change value -> revalidate
        args.storage.validateFieldSync(bcAccount);
        setBankValue(args.storage, args.root ?? args.storage.data.bindingContext, splitted[1]);
        account = splitted[0];
    }

    if (account?.startsWith("0")) {
        account = trimLeadingZeroes(account);
        args.storage.setValue(bcAccount, account);
    }

    // try to create IBAN
    tryToCreateIBAN(args.storage, bcAccount);
};

export const getBankCountry = (args: IGetValueArgs): string => {
    const bc = args.bindingContext.isCollection() ? args.bindingContext : args.bindingContext.getParent();
    const countryBc = bc.navigate("Country");
    return args.storage.getValue(countryBc, { useDirectValue: false });
};


export const isBankCodeVisible = (args: IGetValueArgs): boolean => {
    const country = getBankCountry(args);
    return !country || isBankCodeCountry(country);
};

export const isBankCodeCountry = (country: string): boolean => {
    return BANK_ACCOUNT_BANKS_COUNTRIES.includes(country);
};


export const isBankAccountVisible = (args: IGetValueArgs): boolean => {
    const country = getBankCountry(args);
    return !country || isAccountNumberCountry(country);
};

export const isBankAbaVisible = (args: IGetValueArgs): boolean => {
    return BANK_ABA_COUNTRIES.includes(getBankCountry(args));
};

export const isIBANVisible = (args: IGetValueArgs): boolean => {
    return !isAbaCountry(getBankCountry(args));
};
export const isAbaCountry = (country: string): boolean => {
    return BANK_ABA_COUNTRIES.includes(country);
};

export const isAccountNumberCountry = (country: string): boolean => {
    return BANK_ACCOUNT_COUNTRIES.includes(country);
};

export const isPureIBANCountry = (country: string): boolean => {
    return !isAbaCountry(country) && !isAccountNumberCountry(country);
};

export const AccountNumberGroupField = (args: IGetValueArgs): GroupedField => {
    const country = getBankCountry(args);
    return BANK_ABA_COUNTRIES.includes(country) ? GroupedField.MultiEnd : GroupedField.MultiStart;
};

export const AbaGroupField = (args: IGetValueArgs): GroupedField => {
    const country = getBankCountry(args);
    return BANK_ABA_COUNTRIES.includes(country) ? GroupedField.MultiStart : undefined;
};


export const isIBANRequired = (args: IGetValueArgs): boolean => {
    const country = getBankCountry(args);
    return country !== "CZ";
};

export const handleMarkAsUnrelatedConfirm = async (storage: TSmartODataTableStorage): Promise<boolean> => {
    storage.tableAPI.setState({ loaded: false });
    const activeRows = await storage.tableAPI.getAffectedRows();
    const ids = Array.from(activeRows)?.map((row: IAffectedRow) => {
        return row.key;
    }) || [];

    const result = await customFetch(`${REST_API_URL}/CbaProcessing/MarkAsUnrelatedToBusiness`, {
        ...getDefaultPostParams(),
        body: JSON.stringify({
            PaymentDocumentIds: ids.map(id => parseInt(id))
        })
    });

    let shouldReload = false;
    if (!result || result.ok) {
        storage.data.alert = {
            status: Status.Success,
            title: storage.t("Components:Table.UpdateOk"),
            subTitle: storage.t("Banks:CashBasisAccounting.MarkedAsUnrelated"),
            useFade: true
        };
        shouldReload = true;
    }
    storage.tableAPI.setState({ loaded: true });
    return shouldReload;
};