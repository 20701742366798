import { DevelLocalSettings } from "@utils/LocalSettings";

import { REST_API_URL } from "../constants";
import { HTTPStatusCode } from "../enums";
import { ROUTE_DEVTOOLS } from "../routes";
import customFetch from "../utils/customFetch";

const backendTestModeTimeUrl = `${REST_API_URL}/TestAdmin/GetSystemNow`;

export const isBackendInTestMode = async (): Promise<boolean> => {
    const res = await customFetch(backendTestModeTimeUrl);

    if (res.status === HTTPStatusCode.NotFound) {
        return false;
    }

    return true;
};

export const getBackendTestModeTime = async (): Promise<string> => {
    const res = await customFetch(backendTestModeTimeUrl);

    if (res.status === HTTPStatusCode.NotFound) {
        return null;
    }

    const time = (await res.text()).replaceAll("\"", "");

    return time;
};

export const setFeTimeTravelDate = (date: Date | string): void => {
    if (!date) {
        DevelLocalSettings.remove("timeTravel", "timeTravelDate");
        return;
    }

    DevelLocalSettings.set("timeTravel", {
        timeTravelDate: typeof date === "string" ? date : date.toISOString()
    });
};

export const isInDevtools = (): boolean => {
    return window?.location?.pathname?.toLowerCase?.()?.startsWith(ROUTE_DEVTOOLS.toLowerCase());
};