import { CustomerPortal } from "@pages/home/CustomerPortal.utils";
import { LocalSettingsManager } from "@utils/LocalSettings";
import { logger } from "@utils/log";
import { clearAllMemoizedData } from "@utils/memoize";
import i18next from "i18next";
import Cookies from "js-cookie";

import { API_AUTH_URL, AUTH_DEVICE_URL, AUTH_LOGOUT_URL, LOGIN_PAGE_SUBDOMAIN } from "../../constants";
import { getSystemConfig } from "../../global.utils";
import { clearSessionData } from "../../login/state/sessionSlice";
import store from "../../login/state/store";
import { ROUTE_LOGIN } from "../../routes";
import { getRedirectUriQueryParam } from "../../routes/Routes.utils";

export const CHANGE_PASSWORD_URL = `${API_AUTH_URL}/userSettings/changePassword`;
export const GOOGLE_IS_CONNECTED_URL = `${API_AUTH_URL}/google/isconnected`;
export const DISCONNECT_GOOGLE_ID_URL = `${API_AUTH_URL}/google/disconnect`;
export const FACEBOOK_IS_CONNECTED_URL = `${API_AUTH_URL}/facebook/isconnected`;
export const DISCONNECT_FACEBOOK_ID_URL = `${API_AUTH_URL}/facebook/disconnect`;

export const EVALA_AUTHORIZED_HEADER = "evala-unauthorized";
export const EVALA_FORBIDDEN_HEADER = "evala-forbidden";
export const EVALA_VERSION_HEADER = "evala-version";
export const CENTRAL_SESSION_COOKIE = "CentralSessionId";
export const TENANT_SESSION_COOKIE = "TenantSessionId";
export const EVALA_VERSION_WRONG = "wrong version";

export enum EvalaAuthorized {
    NoSession = "NoSession",
    SessionHasLimitedAccess = "SessionHasLimitedAccess"
}

export enum SessionType {
    // returned by BE when user is completely logged in
    Application = "Application",
    // returned by BE when user is logged in, but tenant hasn't been selected yet
    // after /api/auth/login call, but before /api/auth/session/tenants call
    Login = "Login",
    Customer = "Customer"
}

export const ApplicationSessionTypes: SessionType[] = [
    SessionType.Customer,
    SessionType.Application
];

export enum DeviceType {
    PC,
    Tablet,
    Mobile
}

export interface IAuthDevice {
    Id: string;
    Name: string;
    Type: DeviceType;
    OneTime: boolean;
    IsVerified: boolean;
    DeviceDescription?: IAuthDeviceDescription;
}

export interface IAuthDeviceDescription {
    IPAddress: string;
    OpSystem: string;
    Viewer: string;
}

export interface IPasswordValidation {
    id: string;
    isValid: boolean;
    errMsg: string;
}

export enum PasswordValidation {
    Minimum = "Minimum",
    Maximum = "Maximum"
}

export const MIN_PASSWORD_LENGTH = 12;
// DON'T FORGET ADD "COMMON" TRANSLATION FILE FROM CALLER COMPONENT !!!!!!
export const validatePassword = (password: string): Record<PasswordValidation, IPasswordValidation> => {
    return {
        [PasswordValidation.Minimum]: {
            id: PasswordValidation.Minimum,
            isValid: password?.length >= MIN_PASSWORD_LENGTH,
            errMsg: i18next.t("Common:Errors.ShortPass")
        },
        [PasswordValidation.Maximum]: {
            id: PasswordValidation.Minimum,
            isValid: password?.length < 128,
            errMsg: i18next.t("Common:Errors.LongPass")
        }
    };
};

export const doesSessionCookiesExist = (): boolean => {
    return !!Cookies.get(CENTRAL_SESSION_COOKIE) && !!Cookies.get(TENANT_SESSION_COOKIE);
};

export const clearSessionCookies = (): void => {
    Cookies.remove(CENTRAL_SESSION_COOKIE);
    Cookies.remove(TENANT_SESSION_COOKIE);
};

export const logout = async (fetchFn?: (input: RequestInfo | URL, init?: RequestInit) => Promise<Response>): Promise<void> => {
    try {
        await (fetchFn ?? fetch)(AUTH_LOGOUT_URL);

        clearSessionCookies();
    } finally {
        await clearDataOnLogout();
    }
};

export async function clearDataOnLogout(): Promise<void> {
    // todo - verify this make sense.
    //  user can be logged out in multiple tabs to multiple tenants.
    // maybe the whole local storage (local_settings) concept has to be upgraded to be per tenant?
    // otherwise, variants will fail maybe?
    LocalSettingsManager.clear();
    CustomerPortal.isActive = false;
    clearAllMemoizedData();
    await store.dispatch(clearSessionData());
}

export const fetchAuthDevices = async (): Promise<IAuthDevice[]> => {
    const res = await fetch(AUTH_DEVICE_URL);
    const devices = await res.json();

    return devices;
};

// returns id for paired google account, if connected
export const fetchGoogleIsConnected = async (): Promise<string> => {
    const res = await fetch(GOOGLE_IS_CONNECTED_URL);

    return (await res.json());
};

// unpair the google account
export const disconnectGoogleLogin = async (): Promise<void> => {
    await fetch(DISCONNECT_GOOGLE_ID_URL);
};

// returns id for paired facebook account, if connected
export const fetchFacebookIsConnected = async (): Promise<string> => {
    const res = await fetch(FACEBOOK_IS_CONNECTED_URL);

    return (await res.json());
};

// unpair the facebook account
export const disconnectFacebookLogin = async (): Promise<void> => {
    await fetch(DISCONNECT_FACEBOOK_ID_URL);
};

export const getLoginUrlWithRedirect = (): string => {
    return `${ROUTE_LOGIN}?${getRedirectUriQueryParam()}`;
};

/**
 * Returns full login url with also correct domain according to configuration
 */
export async function getLoginUrl(loginUrl = ROUTE_LOGIN): Promise<{ url: string; isDomainRedirect: boolean }> {
    let url = loginUrl;
    let isDomainRedirect = false;
    try {
        const config = await getSystemConfig();
        if (config?.DisableSubdomains === false) {
            const { protocol } = window.location;
            url = `${protocol}//${LOGIN_PAGE_SUBDOMAIN}.${config.Domain}${loginUrl}`;
            isDomainRedirect = true;
        }
    } catch (e) {
        logger.error("Cannot fetch system config", e);
    }
    return { url, isDomainRedirect };
}
