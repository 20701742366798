import BusyIndicator from "@components/busyIndicator";
import { ButtonSize } from "@components/button/Button.utils";
import { EntitySetName, ISubscriptionEntity } from "@odata/GeneratedEntityTypes";
import { SubscriptionTypeCode } from "@odata/GeneratedEnums";
import React, { useCallback, useEffect, useState } from "react";
import { Trans, useTranslation } from "react-i18next";

import { Button } from "../components/button";
import Clickable from "../components/clickable";
import { ODATA_API_URL } from "../constants";
import TestIds from "../testIds";
import LoginButtonGroup from "./components/LoginButtonGroup";
import LoginCheckbox from "./components/LoginCheckbox";
import {
    EvalaTermsAndConditionsEduLink,
    EvalaTermsAndConditionsLink,
    LoginTranslationNamespaces,
    RequestStatus
} from "./Login.utils";
import { BasicLoginTextBlock, LoginSmallBottomClickable, LoginSmallBottomText, LoginTitle } from "./LoginPage.styles";
import { useLoginDispatch, useLoginSelector } from "./state/hooks";
import {
    activateVerification,
    IAcceptOwnerRoleVerificationInfo,
    selectPostLoginVerifications,
    selectVerificationCode,
    selectVerificationInfo,
    setPostLoginActions
} from "./state/loginSlice";
import { selectSessionRequestStatus } from "./state/sessionSlice";

const RoleConfirmation: React.FC = () => {
    const { t } = useTranslation([...LoginTranslationNamespaces]);
    const dispatch = useLoginDispatch();
    const verificationCode = useLoginSelector(selectVerificationCode);
    const verificationInfo = useLoginSelector(selectVerificationInfo) as IAcceptOwnerRoleVerificationInfo;
    const postLoginVerifications = useLoginSelector(selectPostLoginVerifications);
    const status = useLoginSelector(selectSessionRequestStatus);
    const [checked, setChecked] = useState(false);
    const handleConsentChanged = useCallback(() => {
        setChecked(!checked);
    }, [checked]);

    const onFinish = useCallback(() => {
        // remove RoleConfirmation verification from the set,
        // so that Redirects can move on
        if (postLoginVerifications) {
            dispatch(setPostLoginActions(postLoginVerifications.filter(verification => verification.VerificationGuid !== verificationCode)));
        }
    }, [dispatch, postLoginVerifications]);
    const handleConfirm = useCallback(async () => {
        dispatch(activateVerification(verificationCode, true));
        onFinish();
    }, [dispatch, onFinish, verificationCode]);
    const handleKeepRoleClick = useCallback(async () => {
        dispatch(activateVerification(verificationCode, false));
        onFinish();
    }, [dispatch, onFinish, verificationCode]);
    const [isBusy, setIsBusy] = useState(true);
    const [subscription, setSubscription] = useState<ISubscriptionEntity>(null);
    const isEdu = subscription?.SubscriptionTypeCode === SubscriptionTypeCode.EDU;

    useEffect(() => {
        const url = `${ODATA_API_URL}/${EntitySetName.Subscriptions}`;

        async function fetchSubscription() {
            const res = await fetch(url);

            if (res.ok) {
                const s = await res.json() as ISubscriptionEntity;

                setSubscription(s);
                setIsBusy(false);
            }
        }

        fetchSubscription();

    }, []);

    return (
        <>
            {isBusy && <BusyIndicator isDelayed/>}
            <LoginTitle data-testid={TestIds.Title}>
                {t("Login:RoleConfirmation.Title")}
            </LoginTitle>
            <BasicLoginTextBlock data-testid={TestIds.Text}>
                {t("Login:RoleConfirmation.ExplanationText", {
                    user: verificationInfo?.FrontendParameters?.EditingUserName,
                    tenant: verificationInfo?.FrontendParameters?.TenantName
                })}
            </BasicLoginTextBlock>
            <LoginCheckbox value={checked} onChange={handleConsentChanged}
                           label={(
                               <Trans i18nKey={"Login:RoleConfirmation.Consent"}>
                                   I agree <Clickable
                                   link={isEdu ? EvalaTermsAndConditionsEduLink : EvalaTermsAndConditionsLink} isLink>to
                                   the terms and
                                   conditions</Clickable>
                               </Trans>
                           )}/>
            <LoginButtonGroup additionalText={(
                <>
                    <LoginSmallBottomText data-testid={TestIds.BottomTextLabel}>
                        {t("Login:RoleConfirmation.DeclineText")}
                    </LoginSmallBottomText>
                    <LoginSmallBottomClickable testId={TestIds.BottomTextLink} onClick={handleKeepRoleClick}>
                        {t("Login:RoleConfirmation.DeclineAction", { role: verificationInfo?.FrontendParameters.OriginalRoleName })}
                    </LoginSmallBottomClickable>
                </>
            )}>
                <Button size={ButtonSize.Big}
                        isDisabled={!checked || status === RequestStatus.Pending}
                        onClick={handleConfirm}>{t("Login:RoleConfirmation.Accept")}</Button>
            </LoginButtonGroup>
        </>
    );
};

export default RoleConfirmation;