import { Select } from "@components/inputs/select";
import { IFieldDefFn } from "@components/smart/FieldInfo";
import { IFilterDef } from "@components/smart/smartFilterBar/SmartFilterBar.types";
import { ElectronicSubmissionTypeCode, VatStatementFrequencyCode } from "@odata/GeneratedEnums";
import { Dayjs } from "dayjs";
import i18next from "i18next";
import { memoize } from "lodash";
import React, { ReactElement } from "react";

import Field from "../../../components/inputs/field";
import { BasicInputSizes, FieldType } from "../../../enums";
import { TValue } from "../../../global.types";
import { TableStorage } from "../../../model/TableStorage";
import DateType, { getUtcDate, getUtcDateBy, getUtcDayjs } from "../../../types/Date";
import {
    fetchPossibleSubmissions,
    fetchSubmissionData,
    getSubmissionInfoRowsForYear,
    getVatStatementFrequencyFromSubmission,
    parseSubmissionId,
    SubmissionStatus,
    TPossibleSubmissions
} from "../../electronicSubmission/ElectronicSubmission.utils";
import { getVatSubmissionPeriodName } from "../../electronicSubmission/VatSubmission.utils";
import { CommonReportProps } from "../CommonDefs";
import { IDateRangeParam } from "../Report.utils";
import { ReportStorage } from "../ReportStorage";
import { VatReportProps } from "../VatReport.utils";
import { ISelectionChangeArgs, ISelectItem } from "@components/inputs/select/Select.types";

export const vatPeriodParameterOnBeforeLoadCallback = async (storage: ReportStorage, type: ElectronicSubmissionTypeCode): Promise<void> => {
    const {
        firstVatPeriodDate,
        lastVatPeriodDate,
        submissions,
        possibleSubmissions,
        year
    } = await fetchSubmissionData({
        context: storage.context,
        oData: storage.oData
    });

    const periodBc = storage.data.bindingContext.navigate(VatReportProps.vatStatementPeriod);
    const periodInfo = storage.getInfo(periodBc);

    const fnBuildItem = (startDate: Dayjs, endDate: Dayjs, vatFrequency: VatStatementFrequencyCode, customData?: Record<string, unknown>) => {
        let label = getVatSubmissionPeriodName(startDate, vatFrequency);

        label = label.replace(/\d{4}/, "").trim();

        return {
            id: `${startDate.year()}/${startDate.month()}`,
            label,
            additionalData: {
                DateStart: startDate,
                DateEnd: endDate,
                ...(customData ?? {})
            }
        };
    };

    // add item for each existing submission of given type
    periodInfo.fieldSettings.items = Array.from(submissions.values())
        .filter(submission => submission.ElectronicSubmissionTypeCode === type)
        .map((submission) => {
            const startDate = getUtcDayjs(submission.DatePeriodStart);
            const endDate = getUtcDayjs(submission.DatePeriodEnd);
            const vatFrequency = getVatStatementFrequencyFromSubmission(submission);

            return fnBuildItem(startDate, endDate, vatFrequency);
        });

    // add item for each unlocked submission that doesn't have a corresponding BE item yet
    // fetch possible submissions for every possible year
    const promises: (Promise<TPossibleSubmissions> | TPossibleSubmissions)[] = [];
    const years: number[] = [];

    for (let i = firstVatPeriodDate.year(); i <= lastVatPeriodDate.year(); i++) {
        if (i === year) {
            // no need to fetch again already fetched data
            promises.push(possibleSubmissions);
        } else {
            promises.push(fetchPossibleSubmissions(i));
        }
        years.push(i);
    }

    const res = await Promise.all(promises);

    for (let i = 0; i < res.length; i++) {
        const possibleSubmissions = res[i];
        const year = years[i];
        const overviewRows = getSubmissionInfoRowsForYear({
            year,
            submissions: submissions,
            possibleSubmissions,
            lastVatPeriodDate,
            firstVatPeriodDate,
            context: storage.context
        });

        for (const row of overviewRows) {
            const submissionInfo = row[type];

            if (submissionInfo.status === SubmissionStatus.Unlocked) {
                const { month } = parseSubmissionId(submissionInfo.submissionId);
                const dateStart = getUtcDayjs(getUtcDateBy(year, month, 1));
                const startDate = dateStart;
                const endDate = dateStart.add(submissionInfo.frequency === VatStatementFrequencyCode.Quarterly ? 3 : 1, "month");

                periodInfo.fieldSettings.items.push(
                    fnBuildItem(startDate, endDate, submissionInfo.frequency, { [VatReportProps.unlockedElectronicSubmissionDocuments]: true })
                );
            }
        }
    }

    const dateRange: IDateRangeParam = storage.getValue(periodBc);

    if (!dateRange) {
        const lastLockedPeriod = periodInfo.fieldSettings.items.filter(item => !item.additionalData[VatReportProps.unlockedElectronicSubmissionDocuments]).slice(-1)?.[0];
        const value = (lastLockedPeriod?.additionalData ?? null) as unknown as TValue;

        storage.setFilterValue(periodBc, value ? {
            DateStart: lastLockedPeriod.additionalData.DateStart,
            DateEnd: lastLockedPeriod.additionalData.DateEnd
        } as unknown as TValue : null, value);
        storage.setValueByPath(VatReportProps.unlockedElectronicSubmissionDocuments, !!lastLockedPeriod?.additionalData?.[VatReportProps.unlockedElectronicSubmissionDocuments]);
    }

    storage.refreshField(VatReportProps.vatStatementPeriod);
};

const findMatchingVatPeriod = (periodItems: ISelectItem[], currentDateRange: { DateStart: Date, DateEnd: Date }) => {
    if (!currentDateRange) {
        return periodItems[periodItems.length - 1];
    }

    return periodItems.find((period) => {
        return DateType.isSameDay(period.additionalData?.DateStart, getUtcDate(currentDateRange?.DateStart))
            && DateType.isSameDay(period.additionalData?.DateEnd, getUtcDate(currentDateRange?.DateEnd));
    });
};

const getYearSelectItems = memoize((periodItems: ISelectItem[]): ISelectItem[] => {
    const years = new Set<number>();

    for (const period of periodItems) {
        const year = getUtcDate(period.additionalData.DateStart).getFullYear();
        years.add(year);
    }

    return Array.from(years).map((year: number) => {
        return {
            id: year,
            label: year.toString()
        };
    });
});

export const getRenderVatStatementParam = () => {
    return (args: IFieldDefFn): ReactElement => {
        const storage = args.storage as ReportStorage;
        const periodBc = storage.data.bindingContext.navigate(VatReportProps.vatStatementPeriod);
        const dateRange = storage.getValue(periodBc);
        const isDisabled = !!(storage as TableStorage).data.predefinedFilters?.[VatReportProps.vatStatementPeriod];
        const allPeriodItems = args.props.info.fieldSettings.items ?? [];

        const handleYearChange = (e: ISelectionChangeArgs) => {
            const year = e.value as number;
            const periodItem = allPeriodItems.find((item) => {
                return getUtcDate(item.additionalData.DateStart).getFullYear() === year;
            });

            const value = {
                DateStart: periodItem?.additionalData?.DateStart,
                DateEnd: periodItem?.additionalData?.DateEnd
            };

            storage.settings = {
                ...storage.settings,
                [VatReportProps.unlockedElectronicSubmissionDocuments]: !!periodItem.additionalData[VatReportProps.unlockedElectronicSubmissionDocuments]
            };

            args.props.onChange({
                bindingContext: periodBc,
                value,
                parsedValue: {
                    from: value.DateStart,
                    to: value.DateEnd
                }
            });
        };
        const handlePeriodChange = (e: ISelectionChangeArgs) => {
            const { DateStart, DateEnd } = e.additionalData;

            const value = {
                DateStart,
                DateEnd
            };

            storage.settings = {
                ...storage.settings,
                [VatReportProps.unlockedElectronicSubmissionDocuments]: !!e.additionalData[VatReportProps.unlockedElectronicSubmissionDocuments]
            };
            args.props.onChange({
                bindingContext: periodBc,
                value,
                parsedValue: {
                    from: DateStart,
                    to: DateEnd
                }
            });
        };

        const yearSelectItems = getYearSelectItems(allPeriodItems);
        const matchingPeriod = findMatchingVatPeriod(allPeriodItems, dateRange) ?? allPeriodItems.slice(-1)?.[0];
        const currentYear = matchingPeriod?.additionalData?.DateStart ? getUtcDayjs(matchingPeriod.additionalData.DateStart).year() : null;
        const periodItems = allPeriodItems.filter((item) => {
            return getUtcDate(item.additionalData.DateStart).getFullYear() === currentYear;
        });

        return (
            <>
                <Field
                    name={`${VatReportProps.vatStatementPeriod}/year`}
                    isLight
                    label={storage.t("Reporting:Parameters.Year")}
                    isDisabled={isDisabled}
                    isSharpRight>
                    <Select
                        name={`${CommonReportProps.dateRange}/year`}
                        items={yearSelectItems}
                        value={currentYear}
                        onChange={handleYearChange}
                        isDisabled={isDisabled}
                        width={BasicInputSizes.M}
                        isSharpRight/>
                </Field>
                <Field
                    name={VatReportProps.vatStatementPeriod}
                    label={args.props.info.label}
                    isDisabled={isDisabled}
                    isSharpLeft>
                    <Select
                        name={VatReportProps.vatStatementPeriod}
                        items={periodItems}
                        value={matchingPeriod?.id ?? periodItems?.[0]?.id}
                        onChange={handlePeriodChange}
                        isDisabled={isDisabled}
                        width={BasicInputSizes.M}
                        isSharpLeft/>
                </Field>
            </>
        );
    };
};


export const vatStatementPeriodParamDef = (): IFilterDef => {
    return {
        id: VatReportProps.vatStatementPeriod,
        type: FieldType.Custom,
        label: i18next.t("Reporting:Parameters.DateRange"),
        fieldSettings: {
            items: []
        },
        render: getRenderVatStatementParam(),
        formatter: (val) => {
            const value = val as unknown as { DateStart: Date, DateEnd: Date };
            return DateType.format(value?.DateStart) + " - " + DateType.format(value?.DateEnd);
        }
    };
};